
import React from 'react'
import './About.css'
import { useState } from 'react';
import Zoom from 'react-reveal/Zoom'
import Fade from 'react-reveal/Fade'

import { PiBuildingOffice } from "react-icons/pi";
import { BsClipboardData } from "react-icons/bs";
import { PiCommandThin } from "react-icons/pi";
import { BsGraphUpArrow } from "react-icons/bs";
import FPicture1 from '../../image/Main.jpg'
import { Rotate } from 'react-reveal';
const About = () => {


  return (
   <>
   <div className='container-fluid' >


    <div className='container about-section' id="about">
          
         
   
           <div className="row">
   
           <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
           
           <Zoom   duration={1500}> 
                <div  >  <img  className='image-feature-main' src={FPicture1} alt="team image"></img> </div>
                  </Zoom>

           </div>

            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 row">

                 
           <div className='section-name'>
                
                <div className='section_title section_title_main_side'>
    
                    <h2>   About    </h2>   </div>
    
                <div>
                 
                <div className='section_title_main_side-Details'>
    
                <p>NDIS is a software company that focuses on business system solutions.</p>
                <p></p>
                <p>We aim to provide software that will help cooperative and business to monitor their business anytime, anywhere they want.</p>
               
               <p></p>
               <p>
               With over 17 years of experience in software development, delivering more than 40 successful software solutions across diverse industries. From oil and gas to banking, cooperatives, and manufacturing, internation school, we specialize in crafting customized, scalable, and innovative software that meets the unique challenges and demands of each sector.
               </p>
               
                </div>
                </div>
    
                </div>
    

      
    


            </div>

           
         
     
       </div>

   
   
       </div>
    </div>
 
    </>
   
  )
}

export default About