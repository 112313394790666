import React from "react";
import Navbar from "./navbar/Navbar";
import NavbarMobileView from "./navbar/NavbarMobileView";
import "./App.css"
import "./GoogleFont.css"
import Home from "./components/home/Home";
import Client from "./components/client/Client";
import About from "./components/about/About";
import Status from "./components/status/Status";
import Services from "./components/services/Services";
import Features from "./components/features/Features";
import Porfolio from "./components/porfolio/Porfolio";
import Faq from "./components/faq/Faq";
import TeamMember from "./components/teammember/TeamMember";
import Testimonial from "./components/testimonial/Testimonial";
import Contact from "./components/contact/Contact";
import Footer from "./components/footer/Footer";
import ScrollToTop from "react-scroll-to-top";
import CarouselPage from "./components/carousel/CarouselPage";
import Organization from "./components/organizationchart/Organization";
import OrgChartPage from "./components/orgchart/OrgChartPage";
import Videos from "./components/videos/Videos";


function App() {
  return (
    <>
     <div >

    <NavbarMobileView/>
    <Navbar/>

{/* 
   <Home/> */}

   <CarouselPage/>

    <About/>

    <Features/>
    
   <Services/>
    {/* <Porfolio/>
      */}
     <Videos/>

   
    <Contact/>

   
    <Footer/>   
   
    </div>
    <ScrollToTop smooth
    color='white'
    height='20px'
    width='20px'
     style={{ margin:"0px", borderRadius:"90px", backgroundColor:"#df4512"}}

    />

    </>
  );
}

export default App;
