import React from 'react'
import './Videos.css'
import ReactPlayer from 'react-player/youtube'
// import ReactPlayer from 'react-player/lazy'
const Videos = () => {


    const data = [
        {
          id: 1,
          url: "https://youtu.be/ApjASsEs6WE",
          title: "Next Driven Dash Bord",
          content:
          "",
        },
    
        {
          id: 2,
          url: "https://youtu.be/i8Yf0-cij9Y",
          title: "Report Overview",
          content:
          "",
        },
    
        {
          id: 3,
          url: "https://youtu.be/D7bvcgdgg-s",
          title: "Loan and Accounting",
          content:
          "",
        },
    
   
        {
          id: 4,
          url: "https://youtu.be/rjsEUjPFJss",
          title: "Overview",
          content:
            "",
        },
    
       
      ];
    

  return (

    <div className='conainer-fluid' style={{backgroundColor:'whitesmoke', paddingBottom:'30px'}}>

    <div className='container video-section' >
   
   <div className='section_title section_title_main'>

<h2>  Features  </h2>

</div>

       <div className='row'>


                     {data.map((item,index) => (
                          
                           <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12'> 
                          <div className='video-details' key={index}>
                          
                               <ReactPlayer
                                url={item.url} width="auto" height="250px"
                                config={{
                                    youtube: {
                                    playerVars: { showinfo: 1 }
                                    },
                                    facebook: {
                                    appId: '12345'
                                    }
                                    }}
                                />

                                <h6> {item.title}  </h6>
                                {/* <p>
                                {item.content}
                                </p> */}

                        </div>
                        
                        

                        </div>

                     ))}
         

       </div>
       


       </div>
    </div>
  )
}

export default Videos