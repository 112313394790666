import React, { useState } from "react";
import "./NavbarMobileView.css";
import { GiHamburgerMenu } from "react-icons/gi";
import { Link } from "react-scroll";


const NavbarMobileView = ({ theme, handleChangeTheme }) => {
  const [open, setOpen] = useState(false);

  const handleNavbaropen = () => {
    setOpen(!open);
  };

  const handleClick = () => {
    setOpen(false);
  };
  

  return (
    <>
    
    <div className="responsive-mobile-view">
      <div className="container-fluid mobile-view-header" >
        <p >
          <GiHamburgerMenu size={25} onClick={handleNavbaropen} />
        </p>
      </div>
      <div className="fix-name">
          <h4>NEXT DRIVEN</h4> 
      </div>
      {open ? (
          
        <div className="mobile-view-right">
            
        <div className="mobile-nav">
           
          <ul>
            <li className="nav-item">  <Link to="home"  spy={true}  smooth={true}  offset={-100}  duration={100}  onClick={handleClick} >  Home </Link>  </li>
            <li className="nav-item">  <Link  to="about"  spy={true}   smooth={true}   offset={-100}   duration={100}  onClick={handleClick} >  About  </Link>
            </li> <li className="nav-item"> <Link  to="services"  spy={true}   smooth={true}    offset={-100}   duration={100}  onClick={handleClick}> Services </Link></li>
            
            <li className="nav-item">      <Link      to="contact"    spy={true}      smooth={true}    offset={-100}    duration={100} onClick={handleClick} >   Contact </Link> </li>
            <li>
          
            </li>
          </ul>
        </div>

        </div>
      ) : null}
    </div>
    </>
  );
};

export default NavbarMobileView;
