import React from 'react'
import './Contact.css'

import { BsTelephone } from "react-icons/bs";
import { BsGeoAlt } from "react-icons/bs";
import { BsEnvelope } from "react-icons/bs";
import { LuClock3 } from "react-icons/lu";

const Contact = () => {
  return (
   
     
    <section id="contact" class="contact section">
  

    <div class="container">
     
    <div className='section_title section_title_main'>

            <h2>  Contact  </h2>
         </div>
                    <div>
                      <br/>
                      <p>
                        </p>
                    
                    </div>
       
      <div class="row gy-4">

        <div class="col-lg-6">

          <div class="row gy-4">
            <div class="col-md-6">
              <div class="info-item card">
                <BsGeoAlt  size={40} className='icon-contact'/>
                <h3>Address</h3>
                Unit A5 and A7
Central Terminal M Villarica
San Jose Del Monte Bulacan
              </div>
            </div>

            <div class="col-md-6">
            <div class="info-item card">
               <BsTelephone  size={40} className='icon-contact'/>
                <h3>Call Us</h3>
                <p>0917-153-1882</p>
           
              </div>
            </div>

            <div class="col-md-6">
            <div class="info-item card">
                <BsEnvelope size={40}  className='icon-contact'/>
                <h3>Email Us</h3>
                <p>info@nextdriven.net</p>
              </div>
            </div>

            <div class="col-md-6">
            <div class="info-item card">
                <LuClock3  size={40}  className='icon-contact'/>
                <h3>Open Hours</h3>
                <p>Monday - Friday</p>
                <p>9:00AM - 06:00PM</p>
              </div>
            </div>

          </div>

        </div>

        <div class="col-lg-6">
          <form >
            <div class="row gy-4">

              <div class="col-md-6">
                <input type="text" name="name" class="form-control" placeholder="Your Name" required=""/>
              </div>

              <div class="col-md-6 ">
                <input type="email" class="form-control" name="email" placeholder="Your Email" required=""/>
              </div>

              <div class="col-12">
                <input type="text" class="form-control" name="subject" placeholder="Subject" required=""/>
              </div>

              <div class="col-12">
                <textarea class="form-control" name="message" rows="6" placeholder="Message" required=""></textarea>
              </div>

              <div class="col-12 text-center">
                {/* <div class="loading">Loading</div>
                <div class="error-message"></div>
                <div class="sent-message">Your message has been sent. Thank you!</div> */}

                <button type="submit" className='button'>Send Message</button>
              </div>

            </div>
          </form>
        </div>

      </div>

    </div>

  </section>

  )
}

export default Contact