import Carousel from 'react-bootstrap/Carousel';
import Home from '../home/Home';
import Home1 from '../home/Home1';
const CarouselPage = () => {



  return (

    
    <Carousel interval={3000}>
      <Carousel.Item>
         <Home/ >
          {/* <img  className='d-block w-100' style={{height:'90vh'}} src='./images/team/team-1.jpg'></img> */}
        <Carousel.Caption>
          {/* <h3>First slide label</h3>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
         <Home1/ >
          {/* <img  className='d-block w-100' style={{height:'90vh'}} src='./images/team/team-1.jpg'></img> */}
        <Carousel.Caption>
          {/* <h3>First slide label</h3>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
        </Carousel.Caption>
      </Carousel.Item>
      {/* <Carousel.Item>
          <img  className='d-block w-100' style={{height:'90vh'}} src='./images/team/team-2.jpg'></img>
        <Carousel.Caption>
          <h3>Second slide label</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
      <img  className='d-block w-100' style={{height:'90vh'}} src='./images/team/team-3.jpg'></img>
        <Carousel.Caption>3
          <h3>Third slide label</h3>
          <p>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
          </p>
        </Carousel.Caption>
      </Carousel.Item> */}
    </Carousel>
  )
}

export default CarouselPage