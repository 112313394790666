import { BsBriefcase } from "react-icons/bs";
import { GoChecklist } from "react-icons/go";
import { LuBarChart4 } from "react-icons/lu";
import { PiBinocularsLight } from "react-icons/pi";
import { BsBrightnessHigh } from "react-icons/bs";
import { BsCalendar4Week } from "react-icons/bs";

import Fade from 'react-reveal/Fade';
 import './Services.css'
import React from 'react'

const Services = () => {
  return (
    <>

    <div className="container"  id="services" >
        <div className='section_title section_title_main'>

                <h2>  Services  </h2>
        </div>

        <div className="row services-details-main">
         
            
        <Fade Fade right  duration={1000} >
            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 '>
                   <div className='services-details'>
                            <div className='card-design card'>
                                  <div className='services-list-item'>

                                  <div class="service-item d-flex">
                                    <div class="icon flex-shrink-0"> <LuBarChart4 size={100}  /></div>
                                    <div>
                                        <h3 class="title">Inventory Management</h3>
                                        <p class="title">Help businesses manage stock levels, orders, sales, and deliveries, streamlining operations.</p>
                                    </div>
                                    </div>

                                  </div>
                      
                              </div>
                        <div>
                      </div>
                   </div>
                  
             </div>
             
             </Fade>

             <Fade  Fade right   duration={1000} delay={100}  >
            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
                   <div className='services-details'>
                            <div className='card-design card'>
                                  <div className='services-list-item'>

                                  <div class="service-item d-flex">
                                    <div class="icon flex-shrink-0"> <BsBriefcase size={100}/></div>
                                    <div>
                                        <h3 class="title">Accounting Software</h3>
                                        <p class="title">Businesses manage their financial transactions, generate reports, and ensure regulatory compliance.</p>
                                    </div>
                                    </div>

                                  </div>
                      
                              </div>
                        <div>
                      </div>
                   </div>
                  
             </div>
             
             </Fade>

             <Fade right  duration={1000} delay={200}>
            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
                   <div className='services-details'>
                            <div className='card-design card'>
                                  <div className='services-list-item'>

                                  <div class="service-item d-flex">
                                    <div class="icon flex-shrink-0"> <GoChecklist size={100}/></div>
                                    <div>
                                        <h3 class="title">Website Development</h3>
                                        <p class="title"> Modern website development platforms provide tools to build responsive, mobile-friendly.</p>
                                    </div>
                                    </div>

                                  </div>
                      
                              </div>
                        <div>
                      </div>
                   </div>
                  
             </div>
             
             </Fade>
            
             <Fade right  duration={1000} delay={300}>
            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
                   <div className='services-details'>
                            <div className='card-design card'>
                                  <div className='services-list-item'>

                                  <div class="service-item d-flex">
                                    <div class="icon flex-shrink-0"> <PiBinocularsLight size={100}/></div>
                                    <div>
                                        <h3 class="title">Dental Management</h3>
                                        <p class="title">Helps dental practices manage appointments, patient records, billing, and treatment plans.</p>
                                    </div>
                                    </div>

                                  </div>
                      
                              </div>
                        <div>
                      </div>
                   </div>
                  
             </div>
             
             </Fade>
             <Fade right  duration={1000} delay={400}>
            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
                   <div className='services-details'>
                            <div className='card-design card'>
                                  <div className='services-list-item'>

                                  <div class="service-item d-flex">
                                    <div class="icon flex-shrink-0"> <BsBrightnessHigh size={100}/></div>
                                    <div>
                                        <h3 class="title">Loan Management</h3>
                                        <p class="title">Financial institutions manage the complete lifecycle of loans, from application to repayment.</p>
                                    </div>
                                    </div>

                                  </div>
                      
                              </div>
                        <div>
                      </div>
                   </div>
                  
             </div>
             
             </Fade>
             <Fade right  duration={1000} delay={500}>
            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
                   <div className='services-details'>
                            <div className='card-design card'>
                                  <div className='services-list-item'>

                                  <div class="service-item d-flex">
                                    <div class="icon flex-shrink-0"> <BsCalendar4Week size={100}/></div>
                                    <div>
                                        <h3 class="title">Point of Sale (POS)</h3>
                                        <p class="title">POS software is used by businesses to process transactions, manage sales, track inventory.</p>
                                    </div>
                                    </div>

                                  </div>
                      
                              </div>
                        <div>
                      </div>
                   </div>
                  
             </div>
             
             </Fade>

         </div>          
          
          
    </div>

         
    </>
  )
}

export default Services