import React from 'react'
import './Organization.css'




import { useState } from 'react';

import { Tree, TreeNode } from 'react-organizational-chart';
import { Card, Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
const StyledNode = styled.div`
  padding: px;
  border-radius: 1px;
  display: inline-block;
  border: 1px solid gray;
  lineCode:yellow;
  nodePadding: 1px;
  height: 230px;
  width:180px;
      border-radius: 10px;
    background-color: #38004c;
    color: white;
      box-shadow: -6px 11px 5px -4px rgba(0, 0, 0, 0.42);
    -webkit-box-shadow: -6px 11px 5px -4px rgba(0, 0, 0, 0.42);
    -moz-box-shadow: -6px 11px 5px -4px rgba(0, 0, 0, 0.42);


`;

const OrgData = [
  {
      id:1,
      ID:"1",
      ReportedID:"0",
      image:"",
      Name:"Gary",
      Title:"Director",
      Level: 0,

  },
  {
    id:2,
    ID:"2",
    ReportedID:"1",
    image:"",
    Name:"Bianca",
    Title:"Manager",
    Level: 1,

},
{
  id:3,
  ID:"3",
  ReportedID:"1",
  image:"",
  Name:"Assa",
  Title:"Manager",
  Level: 1,

},
{
  id:4,
  ID:"4",
  ReportedID:"2",
  image:"",
  Name:"Paul",
  Title:"PR",
  Level: 2,

},
{
  id:5,
  ID:"5",
  ReportedID:"2",
  image:"",
  Name:"Serio",
  Title:"Content Creator",
  Level: 2,

},
{
  id:6,
  ID:"6",
  ReportedID:"3",
  image:"",
  Name:"Mattia",
  Title:"Engineer",
  Level: 3,

},
{
  id:7,
  ID:"7",
  ReportedID:"3",
  image:"",
  Name:"Mai",
  Title:"PR Specialist",
  Level: 3,

},
{
  id:8,
  ID:"8",
  ReportedID:"4",
  image:"",
  Name:"Kangur",
  Title:"Copywriter",
  Level: 3,

},
{
  id:9,
  ID:"9",
  ReportedID:"4",
  image:"",
  Name:"Piip",
  Title:"Intern",
  Level: 4, 

},
]




const data101 = OrgData.filter((item)=> item.ReportedID  == "1");
const data102 = OrgData.filter((item)=> item.ReportedID  == "2");


const Organization = () => {

   const [showchart, setShowChart] = useState(true);
   const showMore = () =>  {
 
    setShowChart(!true);

   }


  return (


     <div className='container-fluid fixed-w-compnent' >
            <div className='section_title section_title_main'>
               <h2>  Organization Chart  </h2>
            </div>

    
            <Tree lineWidth={'2px'} lineColor={'green'} lineBorderRadius={'10px'}  label={<StyledNode>Root 1 </StyledNode>}>

            

            {data101.map((item)=>
        
                      <TreeNode label={<StyledNode>   {item.Name}   </StyledNode>} /> 
       
                
           
           )}
       

       

            </Tree>
     <div className='col-lg-12 col-sm-12'>

     {/* <TransformWrapper initialScale={1} initialPositionX={1000} initialPositionY={1000} >
      {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
        <>
          <Controls />
          <TransformComponent> */}
        
          <Container fluid    >
            <Row>
 
            <Col>
   

<Tree lineWidth={'2px'} lineColor={'green'} lineBorderRadius={'10px'}  label={<StyledNode>Root 1 </StyledNode>}>

<TreeNode label={<StyledNode> <div className='org-name' > R2 Peter Luke </div>  
      <div className='image-organization'> <img src='./images/team/team-1.jpg'></img> 
      </div> <div className='org-position'> IT Head
      </div> <div className='org-department'>IT DEPARTMENT</div> <div><button onClick={showMore}> +</button> </div> </StyledNode>}>

          <TreeNode label={<StyledNode> R3  Grand Child  </StyledNode>} /> 
          <TreeNode label={<StyledNode>R4  Great Grand Child </StyledNode>} />
          <TreeNode label={<StyledNode>R4  Great Grand Child </StyledNode>} />


 </TreeNode>


 <TreeNode label={<StyledNode> <div className='org-name' > R2 Peter Luke </div>  
      <div className='image-organization'> <img src='./images/team/team-1.jpg'></img> 
      </div> <div className='org-position'> IT Head
      </div> <div className='org-department'>IT DEPARTMENT</div> <div><button onClick={showMore}> +</button> </div> </StyledNode>}>

          <TreeNode label={<StyledNode> R3  Grand Child  </StyledNode>} /> 
          <TreeNode label={<StyledNode>R4  Great Grand Child </StyledNode>} />
          <TreeNode label={<StyledNode>R4  Great Grand Child </StyledNode>} />


 </TreeNode>


 <TreeNode label={<StyledNode> 111  </StyledNode>}>

          <TreeNode label={<StyledNode> a  Grand Child  </StyledNode>} /> 
          <TreeNode label={<StyledNode>BBBB </StyledNode>} />
          <TreeNode label={<StyledNode>CCC </StyledNode>} />

                <TreeNode label={<StyledNode> 222  </StyledNode>}>

                  <TreeNode label={<StyledNode> a 22  Grand Child  </StyledNode>} /> 
                  <TreeNode label={<StyledNode>BBBB2 22 </StyledNode>} />
                        <TreeNode label={<StyledNode> 222  </StyledNode>}>

                        <TreeNode label={<StyledNode>CCC 222 </StyledNode>} />
                        </TreeNode>
                  <TreeNode label={<StyledNode>CCC 222 </StyledNode>} />


                  </TreeNode>
 </TreeNode>
 
 
{/* 
<TreeNode label={<StyledNode>Child 3</StyledNode>}>
<TreeNode label={<StyledNode>Grand Child 1</StyledNode>} />
<TreeNode label={<StyledNode>Grand Child 2</StyledNode>} />
</TreeNode>

<TreeNode label={<StyledNode>Child 3</StyledNode>}>
<TreeNode label={<StyledNode>Grand Child 1</StyledNode>} />
<TreeNode label={<StyledNode>Grand Child 2</StyledNode>} />
</TreeNode>

<TreeNode label={<StyledNode>Child 3</StyledNode>}>
<TreeNode label={<StyledNode>Grand Child 1</StyledNode>} />
<TreeNode label={<StyledNode>Grand Child 2</StyledNode>} />
</TreeNode>  */}
</Tree>


               </Col>

            </Row>
       
     
          </Container>


          {/* </TransformComponent>
        </>
      )}
    </TransformWrapper>


         */}
            
             </div>
      </div>

  )
}

export default Organization
