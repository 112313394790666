import React from 'react'
import './Footer.css'
import { FaFacebookSquare, FaInstagramSquare } from "react-icons/fa";
import { BsLinkedin } from "react-icons/bs";
import { AiFillTwitterSquare } from "react-icons/ai";
import { FaFacebookF, FaLinkedinIn, FaYoutube } from "react-icons/fa";
import { AiOutlineTwitter, AiOutlineInstagram } from "react-icons/ai";
import { FaS } from 'react-icons/fa6';


const Footer = () => {
  return (

    <div className='container-fluid footer'>
        <div className='container main-content '>
             <div className='row'>
             <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
              <div className="footer-about-section">
                <h2>About</h2>
                <p>
                NDIS is a software company that focuses on business system solutions.
                </p>

                <div className="footer-social-icons">
                  <p>
                    
                    <a href="https://www.facebook.com/profile.php?id=100071543424142" target="_blank"  >    <FaFacebookF size={25} /></a>
                 
                  </p>


                  <p>
                    
                    <a href="https://www.youtube.com/channel/UCnKu6d3PvTPejhSid3hmXaw#:~:text=Share%20your%20videos%20with%20friends,%20family,%20and%20the%20world" target="_blank"  >    <FaYoutube size={25} /></a>
                 
                  </p>
                  

                  {/* <p>
                    <FaLinkedinIn size={25} />
                  </p>
                  <p>
                    <AiOutlineTwitter size={25} />
                  </p>
                  <p>
                    <AiOutlineInstagram size={25} />
                  </p> */}
                </div>
                {/* <span className="footer-social-icons">
                <p>
                  <FaFacebookSquare size={30} />
                </p>
                <p>
                  <AiFillTwitterSquare size={30} />
                </p>
                <p>
                  <FaInstagramSquare size={30} />
                </p>
                <p>
                  <BsLinkedin size={30} />
                </p>
              </span> */}
              </div>
            </div>

                <div className='col-xl-4 col-lg-4 col-md-12 col-sm-12' >
                <div className='footer-section'>
                 
                <h5> <b>  Our Service</b></h5>      
                
                 </div>
                 <div className='footer-item'>

                 <li>
                    Software Development
                   </li>

                   <li>
                     Website Development
                   </li>
                   <li>
                        Online Support
                   </li>

                   <li>
                      E-Voting Support
                   </li>
                 </div>
               </div>

           

               <div className='col-xl-2 col-lg-2 col-md-12 col-sm-12' >
                <div className='footer-section'>
                    
                
                      <h5> <b>  Contact Us </b></h5>       
                

                 </div>
                 <div className='footer-item'>

                 <div> Unit A5 and A7</div>
                 <div>Central Terminal M Villarica</div>
                    <div>San Jose Del Monte Bulacan</div>
                   <div><strong>Email:</strong> <span>info@nextdriven.net</span></div>
                 </div>
               </div>


             </div>
        </div>
        
    </div>
  )
}

export default Footer