
import React from 'react'
import './Features.css'
import FPicture1 from '../../image/features-light-1.jpg'
import FPicture2 from '../../image/features-light-3a.jpg'
import FPicture3 from '../../image/features-light-3.jpg'
import { Rotate, Zoom } from 'react-reveal'


const Features = () => {


  return (
   <>

<div className='container-fluid' style={{backgroundColor:'whitesmoke'}} >

    <div className='container features-section' id="features" style={{paddingBottom:'30px'}}>
        
          <div className='section_title section_title_main'>

                <h2>  Features  </h2>
            
          </div>

          <p>
                An Online Software Solution with Real-Time Management System enables businesses to manage their operations seamlessly over the web with instant access to data, updates, and analytics.

                </p>
            <div className="row">
           
              
                <div className="col-12 col-md-6  order-lg-1 order-sm-2">
                          <div className='section-name'>
                          <div>
                    
                          <div className='h22'>Loan & Accounting System</div>
                          <p></p>
                          <div className='h33'>A loan system automates the loan application, approval and disbursement processes, reducing paperwork, speeding up decision-making, and ensuring consistency in loan management.</div>

                          
                          </div>

                          </div>
                </div>
                <div className="col-12 col-md-6 order-lg-2 order-sm-1">
                <Rotate left top  duration={1500}> 
                <div  >  <img  className='image-feature' src={FPicture1} alt="team image"></img> </div>
                  </Rotate>
                </div>
            
            </div>

            <div className="row">
          
              
              <div className="col-12 col-md-6 order-lg-3 order-sm-2">
              <div className='section-name'>
                          <div>
                          <div className='h22'>Dental Management System</div>                   
                          <p></p>
                          <div className='h33'>Patient scheduling, treatment planning, billing and insurance claims, charting, and digital imaging integration.</div>
                          

                         
                          </div>

                          </div>
              </div>

              
              <div className="col-12 col-md-6  order-lg-2 ">
              <Rotate left top  duration={1500}  delay={500} > 
           
                <div  >  <img className='image-feature' src={FPicture3} alt="team image"></img> </div>
                </Rotate>

              </div>
         

            </div>
            <div className="row">
           
              
           <div className="col-12 col-md-6  order-lg-1 order-sm-2">
                     <div className='section-name'>
                     <div>
                     <div className='h22'>Inventory System</div>
                     <p></p>
                     <div className='h33'>Patient scheduling, treatment planning, billing and insurance claims, charting, and digital imaging integration.</div>

                     
                     </div>

                     </div>
           </div>
           <div className="col-12 col-md-6 order-lg-2 order-sm-1">
           <Rotate left top  duration={1500}> 
           
           <div  >   <img className='image-feature' src={FPicture2} alt="team image"></img> </div>
             </Rotate>
           </div>
           
       </div>


   
   
    </div>
 </div>
    </>
   
  )
}

export default Features