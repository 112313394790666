import React from 'react'
import './Testimonial.css'
import Slider from 'react-slick'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Fade } from 'react-reveal';


const Testimonial = () => {
    
    const data = [
        {
          img:"./images/testimonials/testimonials-1.jpg",
          content:
            "It is not every day that you come across a passionate and trustworthy financial advisor",
          name: "Jon Doe",
          position: "Full stack software developer",
        },
    
        {
          img:"./images/testimonials/testimonials-2.jpg",
          content:
            "It is not every day that you come across a passionate and trustworthy financial advisor",
          name: "Muller",
          position: "UI/UX Designer",
        },
        {
          img:"./images/testimonials/testimonials-3.jpg",
          content:
            "It is not every day that you come across a passionate and trustworthy financial advisor",
          name: "Charlotte",
          position: "Javascript developer",
        },
        {
          img:"./images/testimonials/testimonials-4.jpg",
              content:
            "It is not every day that you come across a passionate and trustworthy financial advisor",
          name: "Alexander",
          position: "Node js developer",
        },
        {
          img:"./images/testimonials/testimonials-5.jpg",
          content:
            "It is not every day that you come across a passionate and trustworthy financial advisor",
          name: "Emily",
          position: "Nuxt js developer",
        },
     
      ];

      var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        adaptiveHeight: true,
        initialSlide: 2,
        autoplay: true,
        rows: 1,
        slidesPerRow: 1,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };
    
  return (

    <>
 
    <div className='container testimonial-section' id="testimonial" >
         
 
    
    <div className='section_title section_title_main'>

<h2>  Testimonial  </h2>
</div>
                    <div>
                      <br/>
                      <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Duis aute irure dolor in reprehenderit

                      </p>
                    
                    </div>
       

    <div>
        <Fade right duration={2000}> 
        <div className='testimonial-slide'>
        
        <Slider {...settings}>
               {data.map((item,index) => (
                   <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12' key={index}>
                       <div className='card content-slider'>
                       <img src={item.img} alt="slider image" className='center-image' ></img>
                    
                          <h4>{item.name}</h4>
                          <p> {item.position} </p>
 
                          <p> {item.content} </p> 
                         
                      </div>
   
  
   </div>
                    
  
               ))}
         </Slider>
 
       
 
        </div>
        </Fade>
    </div>
      

   
        </div>
        </>

  )
}

export default Testimonial