import React from 'react'
import './Home.css'
import Typewriter from 'typewriter-effect';
import { HiSquares2X2 } from 'react-icons/hi2';
import Fade from 'react-reveal/Fade'
import { Zoom } from 'react-reveal';

const Home = () => {
  return (
    <div className='container-fluid home' id="home">

    

        <div className='container home-content'>
        <Fade up duration={1000}>
        <div>
        <h1>
            <Typewriter
                options={{
                    strings: ['Web Development', 'Online Software Solution', 'Loan & Accounting', 'Inventory Management'],
                    autoStart: true,
                    loop: true,
                    delay: 2,
                }}
            />
        </h1>
        </div>
        
        </Fade>

       
       <Fade up  duration={1000} delay={500}> 
        <div>
        <p>
            Online System: A Gateway to Digitalizing Your Business
          </p>
          <p>
          Access your business from anywhere, on any device, at any time
          </p>
        </div>
        </Fade>
      
          
        <a href="#about"  className='view-details-home-button' >
      
      <span>
           GET START
      </span>    
      </a>
        </div>    
       
        
    </div>
  );
}

export default Home