import React from 'react';

import { Tree, TreeNode } from 'react-organizational-chart';
import styled from 'styled-components';
const StyledNode = styled.div`
  padding: px;
  border-radius: 1px;
  display: inline-block;
  border: 1px solid gray;
  lineCode:yellow;
  nodePadding: 1px;
  height: 230px;
  width:180px;
      border-radius: 10px;
    background-color: #38004c;
    color: white;
      box-shadow: -6px 11px 5px -4px rgba(0, 0, 0, 0.42);
    -webkit-box-shadow: -6px 11px 5px -4px rgba(0, 0, 0, 0.42);
    -moz-box-shadow: -6px 11px 5px -4px rgba(0, 0, 0, 0.42);


`;
const TreeNode1 = ({ node }) => {

  return (

    <Tree lineWidth={'2px'} lineColor={'green'} lineBorderRadius={'10px'}  label={<StyledNode>Root 1 </StyledNode>}>

          {   node.children1.map((child, index) => (
                  <> 
                  <TreeNode label={<StyledNode> <div className='org-name' > R2 Peter Luke </div>  
                    <div className='image-organization'> <img src='./images/team/team-1.jpg'></img> 
                    </div> <div className='org-position'> IT Head
                    </div> <div className='org-department'>IT DEPARTMENT</div> { child.name}  </StyledNode>}>


              

                   { child.children2 && child.children2.map((child1, index) => (
                      <>
                          <TreeNode label={<StyledNode> <div className='org-name' >R2 Peter Luke  </div>  
                                        <div className='image-organization'> <img src='./images/team/team-1.jpg'></img> 
                                        </div> <div className='org-position'> IT Head
                                        </div> <div className='org-department'>IT DEPARTMENT</div>  {child1.name} </StyledNode>}>

                                        { child1.children3 && child1.children3.map((child2) => (

                              
                                
                                    <TreeNode label={<StyledNode>{child2.name}  </StyledNode>} />



                                ))}


                      </TreeNode>

                           
     
                            </>

             
                              
                   ))}   


     
                </TreeNode>

     
                
                        </>   
                   
            ))};
         
</Tree>
  );
};

const OrgChart = ({ data }) => {
  return (
    <div>
      <h2>Organization Chart</h2>
      <ul>
        <TreeNode1 node={data} />
      </ul>
    </div>
  );
};

export default OrgChart; 